import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import TournamentMixin from '@/mixins/TournamentMixin';
import RacesTimer from '@/components/Races/RacesTimer';
import RacesButton from '@/components/Races/RacesButton';
export default {
  props: {
    tournament: Object,
    index: Number,
    gaRaceData: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    RacesTimer: RacesTimer,
    RacesButton: RacesButton
  },
  mixins: [TournamentMixin, Base],
  computed: {
    badgeText: function badgeText() {
      return this.isActive ? 'ACTIVE' : 'COMING SOON';
    }
  }
};