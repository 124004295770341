import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import TournamentMixin from '@/mixins/TournamentMixin';
import RacesItemFinished from '@/components/Races/RacesItemFinished/RacesItemFinished';
import RacesLeaderboardModal from '@/components/Races/RacesLeaderboardModal';
import RacesPrizesModal from '@/components/Races/RacesPrizesModal';
export default {
  props: {
    tournament: {
      type: Object,
      default: function _default() {}
    },
    index: Number
  },
  components: {
    RacesLeaderboardModal: RacesLeaderboardModal,
    RacesPrizesModal: RacesPrizesModal,
    RacesItemFinished: RacesItemFinished
  },
  mixins: [TournamentMixin, Base],
  data: function data() {
    return {
      isShowPrizesModal: false,
      isShowLeaderboardModal: false
    };
  },
  methods: {
    showPrizesModal: function showPrizesModal() {
      this.isShowPrizesModal = true;
    },
    showLeaderboard: function showLeaderboard() {
      this.isShowLeaderboardModal = true;
    }
  }
};